<template>
  <div class="dashboard">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>Upload</h1>
          <form enctype="multipart/form-data" novalidate>
            <div class="dropbox">
              <input
                type="file"
                id="files"
                ref="files"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="processFiles()"
                accept=".csv"
                class="input-file"
              />
              <p>
                Drag your file(s) here to begin
                <br />or click to browse
              </p>
            </div>
            <!--<button
              type="button"
              class="btn btn-secondary btn-lg btn-block"
              :disabled="isClearDisabled"
              v-on:click="clear()"
            >Clear</button>-->
          </form>
          <br />
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block"
            :disabled="isUploadDisabled"
            v-on:click="submitFiles(0)"
          >Upload</button>
          <br />
          <ul class="list-group">
            <li
              v-for="(file) in this.files"
              v-bind:key="file.name"
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              {{ file.file.name }} ({{formatSizeUnits(file.file.size)}})
              <span></span>
              <span>{{ file.status }}</span>
              <!--<span class="badge badge-pill badge-warning">3 Parse Errors</span>-->
            </li>
          </ul>
        </div>
      </div>
      <!-- Setup modal -->

      <div
        class="modal fade"
        id="exampleModalEdit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabelEdit"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Configuration</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Swish name</label>
                  <input
                    v-model="toEdit.id"
                    readonly
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput1"
                    :placeholder="toEdit.id"
                  />
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" 
                    v-model="toEdit.public" :checked="toEdit.public" value id="defaultCheck1" />
                    <label class="form-check-label" for="defaultCheck1">Public (anyone can view)</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="exampleFormControlSelect2">User Access (if not public)</label>
                  <select multiple class="form-control" 
                    :disabled="toEdit.public"
                    v-model="toEdit.viewers" id="exampleFormControlSelect2">
                    <option v-for="user in users" v-bind:key="user">
                      {{ user }}
                    </option>
                  </select>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" 
                v-on:click="edit(toEdit)">Save changes</button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Are you sure?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              This will delete all the entries for
              <b>{{ toRemove.id }}</b> from the database.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn btn-danger"
                v-on:click="remove(toRemove)"
                data-dismiss="modal"
              >Remove</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h1>Swishes</h1>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Is Public</th>
                <th scope="col">Access List</th>
                <th scope="col">Last Modified</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(swish,i) in swishes" :key="i">
                <th scope="row">{{i+1}}</th>
                <td>{{swish.id}}</td>
                <td scope="row">{{swish.public}}</td>
                <td scope="row">{{swish.viewers}}</td>
                <td>{{new Date(swish.lastModified)}}</td>
                <td>
                  <a v-bind:href="`/?swish=${swish.id}`">View</a>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    v-on:click="toEdit = swish"
                    data-toggle="modal"
                    data-target="#exampleModalEdit"
                  >Edit</button>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    v-on:click="toRemove = swish"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h1>Users</h1>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Last Modified</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user,i) in users" :key="i">
                <th scope="row">{{i+1}}</th>
                <td>{{user}}</td>
                <td>{{new Date()}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as pako from "pako";
import * as axios from "axios";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  data() {
    return {
      toRemove: {},
      toEdit: { viewers: []},
      swishes: [],
      users: [],
      files: [],
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "swish"
    };
  },
  computed: {
    isUploadDisabled() {
      return !this.files.length > 0;
    },
    isClearDisabled() {
      return !this.files.length > 0;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  mounted() {
    this.getSwishes();
    this.getUser();
  },

  methods: {
    edit(swish) {
      console.log(swish.id)
      console.log(swish)
      axios.post(window.env.apiUrl + "swish/" + swish.id, swish, {
        headers: {
            Authorization: "Bearer " + localStorage.token
      }})
      .then((response) => console.log(response))
      .catch(error => {
        console.error(error);
      })
    },
    remove(swish) {
      console.log("removing " + swish.id);
      axios
        .delete(window.env.apiUrl + "swish/" + swish.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(() => {
          console.log("Successfully Deleted!");
        })
        .catch(error => {
          console.error(error);
        });
    },
    getUser(){
      console.log("getting users")
      axios.get(window.env.apiUrl + "users/", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
      })
      .then((data) => {
        console.log(data)
        return data
      })
      .then(response => (this.users = response.data))
      .catch(error => {
        console.error(error);
      });
    },
    getSwishes() {
      axios
        .get(window.env.apiUrl + "swish/", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => (this.swishes = response.data))
        .catch(error => {
          console.error(error);
        });
    },
    clear() {
      this.files = [];
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    formatSizeUnits(bytes) {
      if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + " GB";
      } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + " MB";
      } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(2) + " KB";
      } else if (bytes > 1) {
        bytes = bytes + " bytes";
      } else if (bytes == 1) {
        bytes = bytes + " byte";
      } else {
        bytes = "0 bytes";
      }
      return bytes;
    },
    processFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push({
          file: this.$refs.files.files[i],
          status: "ready"
        });
      }
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        console.log(`Adding ${JSON.stringify(fieldName)}`);
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // save it
      this.save(formData);
    },
    submitFile(file) {
      return new Promise((resolve, reject) => {
        file.status = `Uploading... 0%`;
        let reader = new FileReader();
        reader.onload = event => {
          var contents = event.target.result;
          var output = pako.gzip(contents);
          let newFile = new File([output], file.file.name + ".gz", {
            type: "application/gzip"
          });
          let formData = new FormData();
          formData.append("swish", newFile);
          axios
            .post(window.env.apiUrl + "swish/", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              },
              onUploadProgress: progressEvent => {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(percentCompleted);
                file.status = `Uploading... ${percentCompleted}%`;
              }
            })
            .then(function() {
              console.log("SUCCESS!!");
              file.status = "Success!";
              return resolve();
            })
            .catch(e => {
              try {
                let errors = e.response.data.errors;
                file.status = `${e.response.data.errors.length} Errors`;
                file.errors = errors;
                errors.forEach((e, i) => {
                  console.error(e);
                });
                return reject();
              } catch (e) {
                console.error(e);
                file.status = "unexpected errors";
                return reject();
              }
            });
        };
        reader.onerror = function(event) {
          console.error(
            "File could not be read! Code " + event.target.error.code
          );
        };
        reader.readAsBinaryString(file.file);
      });
    },
    submitFiles(i = 0) {
      if (this.files[i] == null || this.files[i] == undefined) return;
      console.log("submitting " + i);
      this.submitFile(this.files[i])
        .then(() => {
          console.log("Next");
          i++;
          this.submitFiles(i);
        })
        .catch(err => {
          console.log("Failed to upload");
          console.log(err);
          i++;
          this.submitFiles(i);
        });
    },
    /*submitFiles() {
      console.log("submitting");
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        file.status = `Uploading... 0%`;
        var reader = new FileReader();
        reader.onload = event => {
          var contents = event.target.result;
          var output = pako.gzip(contents);
          let newFile = new File([output], file.file.name + ".gz", {
            type: "application/gzip"
          });
          let formData = new FormData();
          formData.append("swish", newFile);
          axios
            .post("http://localhost:8999/swish/", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              },
              onUploadProgress: progressEvent => {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(percentCompleted);
                file.status = `Uploading... ${percentCompleted}%`;
              }
            })
            .then(function() {
              console.log("SUCCESS!!");
              file.status = "Success!";
            })
            .catch(e => {
              try {
                let errors = e.response.data.errors;
                file.status = `${e.response.data.errors.length} Errors`;
                file.errors = errors;
                errors.forEach((e, i) => {
                  console.error(e);
                });
              } catch (e) {
                console.error(e);
                file.status = "unexpected errors";
              }
            });
        };
        reader.onerror = function(event) {
          console.error(
            "File could not be read! Code " + event.target.error.code
          );
        };
        reader.readAsBinaryString(file.file);
      }
    },*/

    /*
        Handles a change on the file upload
      */
    handleFilesUpload() {
      console.log(pako);
      console.log("handlefileupload");
      this.files = this.$refs.files.files;
      console.log(this.files);
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        console.log(file);
        var reader = new FileReader();
        reader.onload = function(event) {
          var contents = event.target.result;
          var output = pako.gzip(contents);
          let newFile = new File([output], file.name + ".gz", {
            type: "application/gzip"
          });
          let formData = new FormData();
          formData.append("swish", newFile);
          axios
            .post(window.env.apiUrl + "swish/", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function() {
              console.log("SUCCESS!!");
            })
            .catch(function() {
              console.log("FAILURE!!");
            });
        };
        reader.onerror = function(event) {
          console.error(
            "File could not be read! Code " + event.target.error.code
          );
        };
        reader.readAsBinaryString(file);
      }
    }
  }
};
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>