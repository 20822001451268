<template>
  <div class="dashboard">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col col-sm">
          <form class="form-signin" @submit.prevent="login">
            <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
            <label for="inputEmail" class="sr-only">Email address</label>
            <input
              v-bind:class="{ 'is-invalid': isInvalid }"
              v-model="email"
              type="email"
              id="inputEmail"
              class="form-control"
              placeholder="Email address"
              required
              autofocus
            />
            <br>
            <label for="inputPassword" class="sr-only">Password</label>
            <input
              v-bind:class="{ 'is-invalid': isInvalid }"
              v-model="password"
              type="password"
              id="inputPassword"
              class="form-control"
              placeholder="Password"
              required
            />
            <!-- <div class="checkbox mb-3">
              <label>
                <input type="checkbox" value="remember-me" /> Remember me
              </label>
            </div>-->
            <div v-if="isInvalid" class="alert alert-danger" role="alert">Incorrect login. Try again!</div>
            <br>
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as axios from "axios";
export default {
  data() {
    return {
      email: "",
      password: "",
      isInvalid: false
    };
  },
  computed: {},
  mounted() {},

  methods: {
    parseJwt (token) {
      try{
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);

      }catch(e){

        return {}
      }
    },
    login() {
      axios
        .post(env.apiUrl+"login", {
          username: this.email,
          password: this.password
        })
        .then(response => {
          let token = response.data.token;
          localStorage.token = token;
          console.log("log in successfull");
          this.isInvalid = false;
          if(this.parseJwt(token).admin){
            this.$router.push('/dashboard')
          }else{
            this.$router.push('/')
          }
        })
        .catch(e => this.isInvalid = true);
    }
  }
};
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>